import { createSlice } from '@reduxjs/toolkit';
import { getBusinessProjectsList } from './businessProjects.thunk';
import { BusinessProjectInitialState } from 'models/interfaces/businessProjects.interface';

const initialState: BusinessProjectInitialState = {
  projects: null,
  loading: false,
  error: null,
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers: {
    [getBusinessProjectsList.pending.toString()]: state => {
      state.projects = null;
      state.loading = true;
      state.error = null;
    },
    [getBusinessProjectsList.fulfilled.toString()]: (state, { payload }) => {
      state.projects = payload.items;
      state.loading = false;
    },
    [getBusinessProjectsList.rejected.toString()]: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export default projectsSlice.reducer;
