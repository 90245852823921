import { createAsyncThunk } from '@reduxjs/toolkit';
import contractsService from 'services/contractsService';
import { RequestParams } from 'utils/mapParams';
import { CommonRequestParams } from 'models/dto/params.dto';
import { ContractDetailsInterface } from 'models/dto/contracts.dto';

export const getContracts = createAsyncThunk(
  'contracts/getContracts',
  async (data: { params: Partial<RequestParams>; id?: number }, thunkApi) => {
    try {
      const res = await contractsService.getContracts(data.params);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkApi.rejectWithValue(err.message.original);
    }
  }
);

export const getContractById = createAsyncThunk(
  'contracts/getContractById',
  async (data: { id: number; params?: CommonRequestParams }, thunkAPI) => {
    try {
      const contract: ContractDetailsInterface = await contractsService.getContractById(data);

      return contract;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);