import { RequestParams } from 'utils/mapParams';
import httpService, { HttpService } from './httpService';
import { CommonRequestParams } from '../models/dto/params.dto';

export class ContractsService {
  constructor(private _httpService: HttpService) {}

  async getContracts(params: Partial<RequestParams>) {
    const res = this._httpService.get('/business/contracts', { queryStringParameters: params });
    return res;
  }

  async getContractById(data: { id: number; params?: CommonRequestParams }) {
    const res = await this._httpService.get(`/business/contracts/${data.id}`, {
      queryStringParameters: data.params,
    });

    return res;
  }
}

const contractsService = new ContractsService(httpService);

export default contractsService;
