import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import debounce from 'lodash.debounce';

import { DropdownList } from 'react-widgets';

import { AppState } from 'store/configureStore';
import { getBusinessProjectsList } from 'store/businessProjects/businessProjects.thunk';
import { useQuery } from 'hooks/queryHook';
import { formatProjects } from 'utils/formatDatas';
import { BusinessProject } from 'models/interfaces/businessProjects.interface';

import styles from '../../components/tableStyles.module.scss';

interface BusinessProjectFilterProps {
  setFilterValue: (value: string, search?: string) => void;
  searchValue?: string;
}

const BusinessProjectFilter = ({ setFilterValue, searchValue }: BusinessProjectFilterProps) => {
  const dispatch = useDispatch();

  const { projects, loading } = useSelector((state: AppState) => state.businessProjectsReducer);
  const { projectId } = useQuery();
  const [selectedProjectKey, setSelectedProjectKey] = useState<string>('');

  useEffect(() => {
    dispatch(getBusinessProjectsList({ page: 1, size: 50 }));
  }, []);

  useEffect(() => {
    if (projects && projectId) {
      const selectedProject = projects.find((project: BusinessProject) => project.id.toString() === projectId);
      if (selectedProject) {
        setSelectedProjectKey(selectedProject.projectKey);
      }
    }
  }, [projects, projectId]);

  const onProjectChange = (project: BusinessProject) => {
    setFilterValue(project.id, searchValue);
    setSelectedProjectKey(project.projectKey);
  };

  const onProjectSearch = useCallback(
    debounce((event: KeyboardEvent) => {
      const target = event.target as HTMLInputElement;
      dispatch(getBusinessProjectsList({ page: 1, size: 50, search: target.value }));
    }, 250),
    []
  );

  const projectList = formatProjects(projects || []);

  return (
    <div className="dropdown-filter">
      <p className="label-wrapper">Project</p>
      <DropdownList
        containerClassName={cx(styles.inputsField, styles.dropdown)}
        value={selectedProjectKey || ''}
        placeholder="All"
        data={projectList}
        textField="name"
        filter="contains"
        onChange={onProjectChange}
        onKeyDown={onProjectSearch}
        busy={loading}
      />
    </div>
  );
};

export default BusinessProjectFilter;
