import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import cx from 'classnames';

import { Card, CardBody } from 'reactstrap';
import { DropdownList } from 'react-widgets';

import BusinessProjectFilter from 'common/BusinessProjectFilter/BusinessProjectFilter';
import BusinessPipelinesFilter from 'common/BusinessPipelinesFilter/BusinessPipelinesFilter';
import EngagementModelFilter from 'common/EngagementModelFilter/EngagementModelFilter';
import Datepicker from 'components/Datepicker/Datepicker';
import Search from 'common/Search/Search';
import Loader from 'common/Loader';
import ContractsTable from './ContractsTable';
import Pagination from 'components/Pagination/Pagination';
import CreateContractModal from 'common/Modals/CreateContractModal/CreateContractModal';

import { getContracts } from 'store/contracts/contracts.thunk';
import { AppState } from 'store/configureStore';

import { useQuery } from 'hooks/queryHook';
import { RequestParams } from 'utils/mapParams';
import { Role } from 'constants/roles';
import { DATE_PICKER, CONTRACTS_STATUSES } from 'constants/common';
import { plus } from '../../constants/icons';

import styles from '../tableStyles.module.scss';

const Contracts = () => {
  const dispatch = useDispatch();

  const { items, total, pages, loading, error } = useSelector((state: AppState) => state.contractsReducer);
  const { role } = useSelector((state: AppState) => state.account?.user);

  const {
    page,
    pageSize,
    fromDate,
    toDate,
    name,
    projectId,
    pipelineId,
    engagementModelId,
    status,
    setPage,
    setPageSize,
    setDateRange,
    setName,
    setBusinessProjectIdData,
    setPipelineIdData,
    setEngagementModelIdData,
    setStatus,
    setFromDate,
    setToDate,
  } = useQuery();

  const [searchData, setSearchData] = useState(name ? name : '');
  const [openCreateContractModal, setOpenCreateContractModal] = useState<boolean>(false);

  const statusValue = CONTRACTS_STATUSES?.find((item: string) => item.toLowerCase().split(' ').join('-') === status);

  const getData = useCallback(() => {
    if (!fromDate || !toDate) {
      setDateRange(
        format(startOfMonth(new Date()), DATE_PICKER.dateFormatToPayload),
        format(endOfMonth(new Date()), DATE_PICKER.dateFormatToPayload)
      );
    } else {
      const params: Partial<RequestParams> = {
        page: +page,
        size: +pageSize,
        fromDate: fromDate,
        toDate: toDate,
        name,
      };

      if (name) {
        params.name = name;
      }
      if (projectId) {
        params.projectId = projectId;
      }
      if (pipelineId) {
        params.pipelineId = pipelineId;
      }
      if (engagementModelId) {
        params.engagementModelId = engagementModelId;
      }
      if (status) {
        params.status = status;
      }
      dispatch(getContracts({ params }));
    }
  }, [dispatch, fromDate, toDate, pageSize, page, name, projectId, pipelineId, engagementModelId, status]);

  useEffect(() => {
    getData();
  }, [dispatch, getData]);

  const canCreateContract = useMemo(
    () => role === Role.vp || role === Role.sales || role === Role.delivery || role === Role.finance,
    [role]
  );

  const onStatusChange = (value: string) => {
    setStatus(value === 'All' ? '' : value.toLowerCase().split(' ').join(' '), searchData);
  };

  const setCurrentPageHandle = (value: number) => {
    if (value !== +page) {
      setPage(value, searchData);
    }
  };

  const setCountPerPageHandler = (value: number) => {
    setPageSize(value, searchData);
  };

  const onSearchChange = (event: React.FormEvent) => {
    setName(searchData.trim());
    event.preventDefault();
  };

  return (
    <>
      {canCreateContract && (
        <div className={styles.buttonWrap}>
          <button className={cx(styles.button, styles.topButton)} onClick={() => setOpenCreateContractModal(true)}>
            {plus} Create Contract
          </button>
        </div>
      )}

      <Card className="main-card mb-3">
        <CardBody>
          <div className="bp-header">
            <div className="filters-block">
              <Search
                searchValue={searchData}
                setSearchValue={setSearchData}
                updateSearchValue={onSearchChange}
                label="Search"
              />

              <div className="dropdown-filter">
                <div className="label-wrapper">Start Date</div>
                <Datepicker
                  selected={fromDate ? new Date(fromDate) : new Date()}
                  dateFormat={DATE_PICKER.dateFormatMonth}
                  showMonthYearPicker={true}
                  onChange={(date: Date) => setFromDate(format(date, DATE_PICKER.dateFormatToPayload))}
                />
              </div>

              <div className="dropdown-filter">
                <div className="label-wrapper">End Date</div>
                <Datepicker
                  selected={toDate ? new Date(toDate) : new Date()}
                  dateFormat={DATE_PICKER.dateFormatMonth}
                  showMonthYearPicker={true}
                  onChange={(date: Date) => setToDate(format(date, DATE_PICKER.dateFormatToPayload))}
                />
              </div>

              <BusinessProjectFilter setFilterValue={setBusinessProjectIdData} searchValue={searchData} />

              <BusinessPipelinesFilter setFilterValue={setPipelineIdData} searchValue={searchData} />

              <EngagementModelFilter setFilterValue={setEngagementModelIdData} searchValue={searchData} />

              <div className="dropdown-filter">
                <div className="label-wrapper">Status</div>
                <DropdownList
                  value={statusValue}
                  data={CONTRACTS_STATUSES}
                  textField="title"
                  placeholder="All"
                  filter="contains"
                  onChange={onStatusChange}
                />
              </div>
            </div>
          </div>

          {items?.length ? (
            <>
              <div className={cx(styles.tableWrapper, 'mb-3')} style={{ overflow: 'unset' }}>
                <ContractsTable data={items || []} currentPage={+page} perPage={+pageSize} />
                <div className={styles.marginBottomTable}>
                  <Pagination
                    pages={pages}
                    perPage={+pageSize}
                    totalCount={total}
                    setCurrentPage={setCurrentPageHandle}
                    currentPage={+page}
                    setCountPerPage={setCountPerPageHandler}
                    count={0}
                  />
                </div>
              </div>
            </>
          ) : loading ? (
            <Loader />
          ) : (
            'Contracts are empty'
          )}
        </CardBody>
      </Card>

      {openCreateContractModal && (
        <CreateContractModal
          onClose={() => setOpenCreateContractModal(false)}
          callback={() => getData()}
          setOpenModal={setOpenCreateContractModal}
        />
      )}
    </>
  );
};

export default Contracts;
