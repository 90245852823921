import React, { useMemo } from 'react';
import cx from 'classnames';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ContractsTableProps, Contract } from 'models/interfaces/contracts.interface';

import styles from '../tableStyles.module.scss';

const ContractsTable = ({ data, currentPage, perPage }: ContractsTableProps) => {
  const coefForNumberFirstColumn = useMemo(
    () => (currentPage && perPage ? perPage * currentPage - perPage + 1 : 1),
    [currentPage, perPage]
  );

  return (
    <Table
      responsive
      hover
      className={cx(styles.tableContainer, styles.minWidth1300, 'align-middle mb-0 custom-table text-left')}
    >
      <thead>
        <tr>
          <th className={styles.firstCellWidth}>#</th>
          <th>Contract Name</th>
          <th>Start Date</th>
          <th>End date</th>
          <th>Legal Entity</th>
          <th>Project</th>
          <th>Pipeline</th>
          <th>Engagement Model</th>
          <th>Status</th>
        </tr>
      </thead>

      <tbody className={cx(styles.textCapitalize)}>
        {data?.map((item: Contract, index: number) => {
          return (
            <tr key={index}>
              <td>{index + coefForNumberFirstColumn}</td>
              <td>
                <Link to={`/dashboard/contract_details/${item.id}`}>{item.name || '-'}</Link>
              </td>
              <td>{item.startDate.replace(/-/g, '/')}</td>
              <td>{item.endDate.replace(/-/g, "/")}</td>
              <td>{item.legalEntity}</td>
              <td>{item.project?.name}</td>
              <td>{item.pipeline?.name}</td>
              <td>{item.engagementModel?.name}</td>
              <td>{item.status}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ContractsTable;