import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { Card, CardBody } from 'reactstrap';
import Loader from '../../../common/Loader';
// import ContractDetailsTable from './ContractDetailsTable';
import { getContractById } from 'store/contracts/contracts.thunk';
import { AppState } from '../../../store/configureStore';
import { clearContracts } from 'store/contracts';
import { Role } from 'constants/roles';
import styles from '../../tableStyles.module.scss';
import wrapStyles from '../wrapper.module.scss';
import { plus } from '../../../constants/icons';

const ContractDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { contract, loading } = useSelector((state: AppState) => state.contractsReducer);
  const { role } = useSelector((state: AppState) => state.account?.user);

  useEffect(() => {
    !!id && dispatch(getContractById({ id: +id }));
    return () => {
      dispatch(clearContracts());
    };
  }, [dispatch, id]);

  const canAddService = useMemo(
    () => role === Role.vp || role === Role.sales || role === Role.delivery || role === Role.finance,
    [role]
  );

  if (loading) {
    return <div className="mb-4">{<Loader />}</div>;
  }
  return (
    <>
      {canAddService && (
        <div className={styles.buttonWrap}>
          <button className={cx(styles.button, styles.topButton)}>{plus} Add Service</button>
        </div>
      )}
      <Card className="main-card mb-3">
        <CardBody>
          <div className="bp-header">
            <div className={wrapStyles.headerName}>{contract?.name}</div>
          </div>
          <div className={wrapStyles.form}>
            <div className={wrapStyles.form_section}>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Status</p>
                <div className={wrapStyles.textCapitalize}>{contract?.status ? contract?.status : '-'}</div>
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Start Date</p>
                <div>{contract?.startDate ? contract?.startDate.replace(/-/g, '/') : '-'}</div>
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">End Date</p>
                <div>{contract?.endDate ? contract?.endDate.replace(/-/g, '/') : '-'}</div>
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Legal Entity</p>
                <div className={wrapStyles.textCapitalize}>{contract?.legalEntity ? contract?.legalEntity : '-'}</div>
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Project</p>
                <div className={wrapStyles.textCapitalize}>
                  {contract?.project?.name ? contract?.project?.name : '-'}
                </div>
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Linked PIR</p>
                {!!contract?.projectInitRequest ? (
                  <a
                    href={`https://winnerdevelop.atlassian.net/browse/${contract?.projectInitRequest?.issueKey}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.textEllipsis}
                  >
                    {contract?.projectInitRequest?.issueKey}
                  </a>
                ) : (
                  <div>-</div>
                )}
                <button className={wrapStyles.pirButton}>Create Linked PIR</button>
              </div>
            </div>
            <div className={wrapStyles.form_section}>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">SignNow Document ID</p>
                <div>{contract?.signNowDocumentId ? contract?.signNowDocumentId : '-'}</div>
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Pipeline</p>
                <div className={wrapStyles.textCapitalize}>
                  {contract?.pipeline?.name ? contract?.pipeline?.name : '-'}
                </div>
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Engagement Model</p>
                <div className={wrapStyles.textCapitalize}>
                  {contract?.engagementModel?.name ? contract?.engagementModel?.name : '-'}
                </div>
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Invoice Due Date</p>
                <div>{contract?.invoiceDueDate ? contract?.invoiceDueDate.replace(/-/g, '/') : '-'}</div>
              </div>
              <div className={wrapStyles.item}>
                <p className="label-wrapper">Payment Basis</p>
                <div className={wrapStyles.textCapitalize}>
                  {contract?.paymentBasis?.name ? contract?.paymentBasis?.name : '-'}
                </div>
              </div>
            </div>
          </div>
          {/* <ContractDetailsTable data={mappedData} /> */}
        </CardBody>
      </Card>
    </>
  );
};
export default ContractDetails;