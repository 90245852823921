import { useSelector } from 'react-redux';

import PageTitle from '../../Layout/AppMain/PageTitle';
import ContractDetails from 'components/ContractsDashboard/ContractsDetails/ContractDetails';
import { contractsIcon } from '../../constants/icons';

import { AppState } from 'store/configureStore';

const ContractDetailsPage = () => {
  const { contract } = useSelector((state: AppState) => state.contractsReducer);

  return (
    <>
      <PageTitle heading={contract?.name ? contract.name : 'Deal Details'} icon={contractsIcon} />
      <ContractDetails />
    </>
  );
};

export default ContractDetailsPage;
